import React,  {useEffect,useState} from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from "react-router-dom";
import ApiHelper from '../API/ApiHelper';
import 'react-bootstrap-typeahead/css/Typeahead.css'; 
import logo from '../assets/SeatSavantsLogo.png';
import { Button } from 'primereact/button';
import { Carousel } from 'primereact/carousel';
import { Tag } from 'primereact/tag';
import football from '../assets/football.jpg';
import basketball from '../assets/basketball.jpg';
import baseball from '../assets/baseball.jpg';
import hockey from '../assets/hockey.jpg';
import concert from '../assets/concert.jpg';
import other from '../assets/misc.jpg';
import soccer from '../assets/soccer.jpg';


export default function HomeTwo(props) {
    const [eventsLoading,setEventsLoading] = useState(true);
    const [isLoading,setIsLoading] = useState(true);
    const [previewEvents,setPreviewEvents] = useState([]);
    const [searchResults,setSearchResults] = useState([]);
    const navigate = useNavigate();
    props.funcNav(false);
    var api = new ApiHelper();
    const initData = async () =>{
        var res = await api.GetPopularEvents();

        setPreviewEvents(res.data);
        setEventsLoading(false);
        setIsLoading(false);
    }
    const handleSearch = async (a) =>{
        setIsLoading(true);
        var res = await api.searchEvents(a);
        setSearchResults(res.data);
        setIsLoading(false);
    }

    const handleSelect = (a) =>{
        var e = a[0];
        if(e == null){
            return;
        }
        navigate('/event/'+e.id);
    }

    useEffect(()=>{
        initData();
    },[])
    const responsiveOptions = [
        {
            breakpoint: '1400px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '575px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    const getSource =(type)=>{
        if(type == 'nba' || type.includes('basketball')){
            return basketball
        }
        if(type == 'mls' || type.includes('soccer')){
            return soccer
        }
        if(type == 'nhl' || type.includes('hockey')){
            return hockey
        }
        if(type == 'mlb' || type.includes('baseball')){
            return baseball
        }
        if(type=='nfl' || type.includes('football')){
            return football
        }
        else{
            return concert
        }
    }
    const eventTemplate = (event) => {
        return (
            <div className='card shadow m-sm-2' style={{height:'95%'}}>
                <a href={"/event/"+event.id} style={{textDecoration:'none'}}>
                <div className='card-body'>
                        <div className="border-1 surface-border border-round m-2 text-center py-1 px-3">
                        <div className="mb-3">
                            <img src={getSource(event.type)} alt={event.name} className="w-6 shadow-2" style={{width:"150px",height:"auto"}} />
                        </div>
                        <div className=''>
                            <h6 className="mb-1 fw-bold">{event.title}</h6>
                            <h6 className="mt-0 mb-3"> Avg: ${event.medianPrice}</h6>
                        </div>
                        <Tag value={event.listingCount +" tickets available"}></Tag>
                    </div>
                </div>
            </a>
                
            </div>
        );
    };




  return (
    <div className='container-fluid' style={{backgroundColor:"#4F5356",height:'100vh'}}>
        <div className='row'>
            <div className='col-12'>
                <div className='d-flex justify-content-center mt-5'>
                    <img src={logo} style={{width:"75vw",height:"auto", maxWidth:"600px"}} alt=""/>
                </div>
            </div>
        </div>
        <div className='row pt-5'>
            <div className='col-sm-3 col-1'>

            </div>
            <div className='col-sm-6 col-10'>
            {
                        !eventsLoading && 
                        <AsyncTypeahead
                            id="eventSearch"
                            className='shadow-lg shadow-intensity-xl'
                            isLoading={isLoading}
                            onSearch={handleSearch}
                            renderMenuItemChildren={(e)=>(
                                <div className='m-2' style={{textWrap:"pretty"}}>
                                    <div className='row'>
                                        <div className='col-sm-6 col-12'>
                                            <h6>{e.title} </h6>
                                            <div className='text-secondary'>{e.venueName} - {e.city}</div>
                                        </div>
                                        <div className='col-sm-6 col-12 text-end text-secondary'>
                                            {(new Date(e.dateTimeLocal)).toLocaleString()}
                                            <span className="badge bg-secondary ms-3 bg-success fw-bold"> Tickets From: ${e.medianPrice}</span>
                                        </div>

                                    </div>
                                    <hr/>
                                </div>
                            )
                            }
                            labelKey="title"
                            options={
                                searchResults
                            }
                            onChange={(selected) => handleSelect(selected)}
                            placeholder={"Search for you favorite artists or events..."}
                        />
                    }
            </div>
            <div className='col-sm-3 col-1'>
            </div>
        </div>
        <div className='row pt-5'>
                    <div className='col-sm-3 col-1'>

                    </div>
                    <div className='col-sm-6 col-12'>
                    {!eventsLoading && 
                        <>
                            <div className=''>
                                <div className='fw-bold text-center'>
                                </div>
                                <Carousel value={previewEvents} numVisible={3} numScroll={3} responsiveOptions={responsiveOptions} itemTemplate={eventTemplate} autoplayInterval={10000}/>
                            </div>

                        </>
                    }
                    </div>
                    <div className='col-sm-3 col-1'>

                    </div>
        </div>
    </div>
  )
}
