import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from './pages/Home'
import Event from './pages/Event'
import THNavbar from './components/THNavbar';
import {useState} from 'react'
import HomeTwo from './pages/HomeTwo';
import EventTwo from './pages/EventTwo'


function App() {
  const [showNav, setShowNav] = useState(true);
  return (
    <BrowserRouter>
      {showNav && <THNavbar/>}
      <Routes>
        <Route path="/" element={<HomeTwo funcNav={setShowNav}/>}/>
        <Route path="/event/:eventId" element={<EventTwo/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
