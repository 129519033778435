import React, { useState,useEffect } from 'react'
import { Timeline } from 'primereact/timeline';
import tm from '../assets/Ticketmaster-logo.png';
import sg from '../assets/Seatgeek_logo.png';
import sh from '../assets/stubhublogo.png';
import vs from '../assets/Vivid-Seats-Logo.jpg';
import tp from '../assets/Tickpick_Logo.jpg';

export default function EventPriceDetails(props) {
    var type = props.type;
    var eventDetails = props.event;
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      }

    useEffect(() => {
    function handleResize() {
        setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    if(type!= "SeatGeek"){
        var offset = Math.random()+.5;
        eventDetails.stats.lowest_price =Math.ceil(eventDetails.stats.lowest_price*offset);
        eventDetails.stats.average_price =Math.ceil(eventDetails.stats.average_price*offset);
        eventDetails.stats.highest_price =Math.ceil(eventDetails.stats.highest_price*offset);
    }

    var priceValues = [
        {"header":"High","value":eventDetails.stats.highest_price},
        {"header":"Avg.","value":eventDetails.stats.average_price},
        {"header":"Low","value":eventDetails.stats.lowest_price}

    ]
    var getImage = () =>{
        if(type == "SeatGeek"){
            return sg;
        }
        if(type == "TicketMaster"){
            return tm;
        }
        if(type=="StubHub"){
            return sh;
        }
        if(type=="Vivid Seats"){
            return vs;
        }
        if(type=="TickPick"){
            return tp;
        }
        return tp;
    }
    if(windowDimensions.width < 800){
        return(<>
        <div className='card col-12 mb-1 shadow'>
            <div className='card-body row'>
                <div className='col-7'>
                    <div className=''>
                        <img src={getImage()} alt="" style={{width:"100px",height:"auto"}}></img>

                    </div>

                </div>
                <div className='col-5'>
                    <div className='pb-2'>
                        <div className='badge bg-success'>Starting at: {eventDetails.stats.lowest_price} </div>
                    </div>
                    <div className='pb-2'>
                        <div className='badge bg-success'>{eventDetails.stats.listing_count} Available</div>
                    </div>
                    <div className=''>
                        <a className='btn btn-primary btn-sm shadow' href={eventDetails.url}>Review Seats</a>
                    </div>
                </div>
            </div>
        </div>
        </>)
    }
    return (
        <div className='card col-12 mb-1 shadow'>
            <div className='card-body row'>
                <div className='col-7 '>
                    <div className=' d-flex justify-content-center mt-2'>
                        <img src={getImage()} alt="" style={{width:"100px",height:"auto"}}></img>

                    </div>
                    <div className=' d-flex justify-content-center mt-4'>
                        <div className='badge bg-success'>{eventDetails.stats.listing_count} Available</div>
                    </div>
                    <div className=' d-flex justify-content-center mt-2'>
                    <a className='btn btn-primary btn-sm shadow' href={eventDetails.url}>Review Seats</a>
                    </div>

                </div>
                <div className='col-5 d-flex justify-content-end'>
                    <Timeline value={priceValues} align="right" content={(item) => 
                    <>
                        <div className="fw-bold">
                            ${item.value} <span className='text-secondary' style={{fontSize:"12px"}}> {item.header}</span>
                        </div>
                    </>
                    } />
                </div>
            </div>
        </div>
    )
}
